<template>
  <div>
    <div class="welcome-message">
      <img :src="logoURL" alt="logo de la empresa" class="logo" />
      <div class="content" :class="{ 'two-column': welcomeMessagePictureURL }">
        <section v-html="welcomeMessage"></section>

        <figure v-if="welcomeMessagePictureURL">
          <img :src="welcomeMessagePictureURL" alt="" />
        </figure>
      </div>

      <custom-button variant="primary" @click="$router.push('survey')">
        Iniciar Encuesta
        <font-awesome-icon :icon="icons.arrowRight" slot="append-icon" />
      </custom-button>
    </div>
  </div>
</template>

<script>
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { mapState } from "vuex";

import CustomButton from "../../components/CustomButton.vue";

export default {
  name: "WelcomeMessage",

  components: { CustomButton },

  computed: {
    ...mapState({
      logoURL: state => state.company.logoURL,
      welcomeMessage: state => state.survey.welcomeMessage,
      welcomeMessagePictureURL: state => state.survey.welcomeMessagePictureURL,
      finishedSurvey: state => state.userProfile.finishedSurvey
    })
  },

  data: () => ({
    icons: {
      arrowRight: faArrowRight
    }
  })
};
</script>

<style scoped>
.view {
  padding: var(--lengthLg2) 0;
  background-color: var(--grayColor1);
}

.welcome-message {
  max-width: 1300px;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  justify-items: flex-start;
  grid-gap: var(--lengthMd3);
  gap: var(--lengthMd3);
  margin: 0 auto;
  padding: var(--lengthMd1) var(--lengthMd1) var(--lengthLg1);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 10px 2px var(--grayColor2);
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--lengthMd3);
}

.logo {
  max-width: 120px;
}

img {
  max-height: 100%;
}

.btn {
  padding: var(--lengthMd1);
  font-size: var(--lengthMd2);
}

@media only screen and (min-width: 768px) {
  .welcome-message {
    padding: var(--lengthLg1);
  }
}

@media only screen and (min-width: 980px) {
  .view {
    max-height: 100vh;
    overflow: hidden;
  }

  .welcome-message {
    height: 100%;
    padding: var(--lengthLg2) var(--lengthLg3);
  }

  .content.two-column {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
